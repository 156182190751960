var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"groups-sidebar",attrs:{"md":"4","cols":"12"}},[_c('DocumentsSidebarWrapper',{ref:"documentsSidebarWrapper"},[_c('v-text-field',{attrs:{"label":_vm.__('company.components.views.projects.projects-show.documents-sidebar.search'),"outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-4"}),_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.items,"open":_vm.open,"search":_vm.search,"activatable":"","color":"primary","item-key":"uuid","return-object":"","transition":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children.length > 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggle($event, item, open)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(open ? 'mdi-chevron-up' : 'mdi-chevron-down')}})],1):_vm._e(),(item.children.length > 0)?_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')}}):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.can([_vm.permissions.company.groups.create, _vm.permissions.company.groups.update, _vm.permissions.company.groups.delete], true, item))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[(_vm.can([_vm.permissions.company.groups.create], false, item))?_c('v-list-item',{on:{"click":function($event){return _vm.addGroup(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.__('company.components.views.projects.projects-show.documents-sidebar.actions.add-group'))}})],1):_vm._e(),(_vm.can([_vm.permissions.company.groups.update, _vm.permissions.company.groups.show], false, item))?_c('v-list-item',{on:{"click":function($event){return _vm.editGroup(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.__('company.components.views.projects.projects-show.documents-sidebar.actions.edit-group'))}})],1):_vm._e(),(_vm.can([_vm.permissions.company.groups.delete], false, item))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteGroup(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.__('company.components.views.projects.projects-show.documents-sidebar.actions.delete-group'))}})],1):_vm._e()],1)],1)],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }