












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Group from '@/shared/modules/group/group.model'
import Project from '@/shared/modules/project/models/project.model'
import GroupService from '@/shared/modules/group/group.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import UploadDocumentsDialog from '@/company/components/documents/UploadDocumentsDialog.vue'
import Attachment from '@/shared/modules/attachment/attachment.model'
import _ from 'lodash'
import __ from '@/shared/helpers/__'
import can from '@/shared/helpers/can.helper'
import permissions from '@/shared/helpers/permissions.helper'
import DocumentActions from '@/company/components/documents/DocumentActions.vue'
import isImageByMimeType from '@/shared/helpers/is-image-by-mime-type.helper'
import SelectedRows from '@/shared/components/data-table/SelectedRows.vue'
import IResponse from '@/shared/interfaces/modules/response.interface'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import User from '@/shared/modules/user/user.model';
import { AuthGetters } from '@/shared/store/auth/auth.getters';
import EditDocumentDialog from '@/company/components/documents/EditDocumentDialog.vue';

@Component({
  components: { SelectedRows, DocumentActions },
  methods: { __, can, isImageByMimeType }
})
export default class DocumentsList extends Vue {
  @Prop() active!: Group
  @Prop() project!: Project

  permissions = permissions
  documents: any|null = null
  selectedDocuments: Attachment[] = []
  meta: any = {
    current_page: 1,
    last_page: 1
  }
  loading: boolean = false

  @Watch('active') private activeChanged() {
    this.meta = {
      current_page: 1,
      last_page: 1
    }
    this.loadAttachments(1)
    this.selectedDocuments = []
  }

  @Watch('meta.current_page') private currentPageChanged() {
    this.loadAttachments(this.meta.current_page)
  }

  upload() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: UploadDocumentsDialog,
      persistent: true,
      maxWidth: 650,
      meta: {
        project: this.project,
        group: this.active,
        onSuccess: () => this.loadAttachments(1)
      }
    })
  }

  async loadAttachments(page: number = 1) {
    if (! this.active) return
    this.loading = true
    let response = await GroupService.attachments(this.company, this.project, this.active, page)

    response.meta.last_page = this.meta.last_page

    if (response.data.length >= 10) {
      response.meta.last_page = page + 1;
    }

    this.meta = response.meta
    this.documents =  _.groupBy(response.data, (attachment: Attachment) => attachment.createdAt)
    this.loading = false
  }

  getDocumentIcon(document: Attachment) {
    if (document.mimeType.startsWith('image')) return 'mdi-file-image'
    if (document.mimeType.includes('pdf')) return 'mdi-file-pdf-box'
    if (document.mimeType.includes('doc')) return 'mdi-file-document'
    if (document.mimeType.includes('zip')) return 'mdi-zip-box'

    return 'mdi-file'
  }

  isSelected(document: Attachment): boolean {
    return this.selectedDocuments.some((item: Attachment) => item.uuid === document.uuid)
  }

  toggleSelectedDocument(document: Attachment): void {
    if (this.isSelected(document)) {
      this.selectedDocuments = this.selectedDocuments.filter((item: Attachment) => item.uuid !== document.uuid)
      return
    }

    this.selectedDocuments.push(document)
  }

  downloadSelected(): void {
    GroupService.massDownloadAttachments(this.company, this.project, this.active, this.selectedDocuments.map((item: Attachment) => item.uuid))
      .then((response: IResponse<any>) => {
        downloadFile(response, `${ _.snakeCase(this.company.slug) }_${ _.snakeCase(this.project.name) }_${ _.snakeCase(this.active.name as string) }_documents.zip`)
      })
  }

  deleteSelected(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.views.projects.projects-show.documents-list.mass-delete.title'),
        text: __('company.components.views.projects.projects-show.documents-list.mass-delete.text', { total: this.selectedDocuments.length }),
        onYes: () => GroupService.massDeleteAttachments(this.company, this.project, this.active, this.selectedDocuments.map((item: Attachment) => item.uuid))
          .then(() => {
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.components.views.projects.projects-show.documents-list.mass-delete.on-success', { total: this.selectedDocuments.length }),
            })

            this.selectedDocuments = []
            this.loadAttachments(1)
          })
      }
    })
  }

  handleDoubleClick(document: Attachment, key: number): void {
    // @ts-ignore
    this.$refs.documentActions[key].editAttachment(document)
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get documentKeys() {
    return Object.keys(this.documents)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
