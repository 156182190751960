






import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Form from '@/shared/components/form/Form.vue'
import PageHeader from '@/admin/components/PageHeader.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import __ from '@/shared/helpers/__'
import {HttpMethod} from '@/shared/helpers/requests.helper';
import Attachment from '@/shared/modules/attachment/attachment.model';

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EditDocumentDialog extends Vue {
  @Prop() meta: any
  form: FormBase = new FormBase()
      .setModel(Attachment)
      .setMethod(HttpMethod.PUT)
      .setEndpoint(`company/${ this.meta.company.slug }/projects/${ this.meta.project.uuid }/groups/${ this.meta.group.uuid }/attachments/${ this.meta.document.uuid }/edit`)
      .setEntry(this.meta.document.customProperties)
      .setFields([
        new Field()
            .setType(FieldTypes.textArea)
            .setKey('description')
            .setTitle(__('company.components.views.projects.projects-show.upload-documents-dialog.form.description')),
      ])
      .setOnSuccess(this.onSuccess)

  onSuccess() {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.components.views.projects.projects-show.edit-document-dialog.form.on-success')
    })

    this.$store.dispatch(GlobalActions.closeDialog)

    this.meta.onSuccess && this.meta.onSuccess()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
