






































import { Component, Prop, Vue } from 'vue-property-decorator'
import permissions from '@/shared/helpers/permissions.helper'
import Attachment from '@/shared/modules/attachment/attachment.model'
import GroupService from '@/shared/modules/group/group.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import __ from '@/shared/helpers/__'
import RotateDocumentDialog from '@/company/components/documents/RotateDocumentDialog.vue'
import Company from '@/shared/modules/company/company.model'
import Project from '@/shared/modules/project/models/project.model'
import Group from '@/shared/modules/group/group.model'
import can from '@/shared/helpers/can.helper'
import _ from 'lodash'
import EditDocumentDialog from '@/company/components/documents/EditDocumentDialog.vue';

@Component({
  methods: { can, __ }
})
export default class DocumentActions extends Vue {
  @Prop() value!: Attachment[]
  @Prop() document!: Attachment
  @Prop() company!: Company
  @Prop() project!: Project
  @Prop() group!: Group
  @Prop() parentRef!: any
  @Prop() date!: string
  @Prop() index!: number
  @Prop() loadAttachments!: () => void

  permissions = permissions

  goToPreview(document: Attachment) {
    const tab: any = window.open(document.url, '_blank')
    tab.focus()
  }

  downloadAttachment(document: Attachment) {
    GroupService.downloadAttachment(this.company, this.project.uuid, this.group.uuid, document)
      .then((response: any) => downloadFile(response, document.name))
  }

  editAttachment(document: Attachment) {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditDocumentDialog,
      maxWidth: 600,
      meta: {
        document,
        project: this.project,
        group: this.group,
        company: this.company,
        onSuccess: this.loadAttachments,
      }
    })
  }

  deleteAttachment(document: Attachment) {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.views.projects.projects-show.documents-actions.delete.title'),
        text: __('company.components.views.projects.projects-show.documents-actions.delete.text', { name: document.name }),
        onYes: () => GroupService.deleteAttachment(this.company, this.project, this.group, document)
          .then(() => this.loadAttachments && this.loadAttachments())
      }
    })
  }

  openRotateAttachmentDialog(document: Attachment, date: string, key: number) {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: RotateDocumentDialog,
      maxWidth: 650,
      meta: {
        document,
        company: this.company,
        project: this.project,
        active: this.group,
        onRotate: (attachment: Attachment) => {
          const documents: any = this.value
          documents[date][key] = attachment
          this.$emit('input', _.cloneDeep(documents))
        }
      }
    })
  }
}
