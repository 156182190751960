






















import { Component, Inject, Vue } from 'vue-property-decorator'
import Project from '@/shared/modules/project/models/project.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DocumentsSidebar from '@/company/components/documents/DocumentsSidebar.vue'
import Group from '@/shared/modules/group/group.model'
import DocumentsList from '@/company/components/documents/DocumentsList.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import UploadDocumentsDialog from '@/company/components/documents/UploadDocumentsDialog.vue'
import __ from '@/shared/helpers/__'
import can from '@/shared/helpers/can.helper'
import permissions from '@/shared/helpers/permissions.helper'

@Component({
  components: { DocumentsSidebar, DocumentsList },
  methods: { __, can }
})
export default class Documents extends Vue {
  @Inject('project') getProject!: () => Project
  permissions = permissions
  active: Group[] = []

  addDocuments() {
    const otherGroup = this.documentSidebar.items[0].children[this.documentSidebar.items[0].children.length - 1]

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: UploadDocumentsDialog,
      persistent: true,
      maxWidth: 650,
      meta: {
        project: this.getProject(),
        group: otherGroup
      }
    })
  }

  get documentSidebar(): DocumentsSidebar|any {
    return this.$refs.documentSidebar
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
