






























import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Attachment from '@/shared/modules/attachment/attachment.model'
import GroupService from '@/shared/modules/group/group.service'
import _ from 'lodash'

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class RotateDocumentDialog extends Vue {
  @Prop() meta!: any
  document!: Attachment
  key: number = Math.random()

  created() {
    this.document = this.meta.document
  }

  rotate() {
    let orientation = Number(_.get(this.document, 'manipulations.thumb.orientation', 0)) + 90
    if (orientation === 360) orientation = 0

    GroupService.rotateAttachment(this.meta.company, this.meta.project, this.meta.active, this.document, orientation)
      .then((attachment: Attachment) => {
        attachment.url += '?' + Math.random()
        this.document = attachment
        this.meta.onRotate && this.meta.onRotate(attachment)

        this.key = Math.random()
      })
  }
}
