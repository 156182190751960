






import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field from '@/shared/classes/form/field'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Group from '@/shared/modules/group/group.model'
import IGroupResponse from '@/shared/modules/group/group-response.interface'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class AddGroupDialog extends Vue {
  @Prop() meta: any
  form: FormBase|null = null

  created() {
    let injectValues: any = {}
    if (this.meta.parent) {
      injectValues.parent_uuid = this.meta.parent.uuid
    }

    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/projects/${ this.meta.project.uuid }/groups`)
      .setTranslatable(true)
      .setValidate(true, { onlyRegionLangRequired: true })
      .setModel(Group)
      .setFields([
        new Field()
          .setTranslatable(true)
          .setKey('name')
          .setTitle(__('company.components.views.projects.projects-show.add-group-dialog.form.name'))
      ])
      .setInjectValues(injectValues)
      .setSubmit({
        text: __('company.components.views.projects.projects-show.add-group-dialog.form.submit')
      })
      .setOnSuccess(this.onSuccess)
  }

  private onSuccess(response: IGroupResponse, group: Group): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.components.views.projects.projects-show.add-group-dialog.form.on-success')
    })
    this.$store.dispatch(GlobalActions.closeDialog)

    this.meta.onSuccess && this.meta.onSuccess(group)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
