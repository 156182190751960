




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Group from '@/shared/modules/group/group.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AddGroupDialog from '@/company/components/documents/groups/AddGroupDialog.vue'
import GroupService from '@/shared/modules/group/group.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Project from '@/shared/modules/project/models/project.model'
import permissions from '@/shared/helpers/permissions.helper'
import can from '@/shared/helpers/can.helper'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import __ from '@/shared/helpers/__'
import EditGroupDialog from '@/company/components/documents/groups/EditGroupDialog.vue'
import DocumentsSidebarWrapper from '@/company/components/documents/DocumentsSidebarWrapper.vue'

@Component({
  components: { DocumentsSidebarWrapper },
  methods: { can, __ }
})
export default class DocumentsSidebar extends Vue {
  @Prop() project!: Project
  permissions = permissions
  active: Group[] = []
  open: Group[] = []
  search: string | null = null
  items: any[] = []

  @Watch('active')
  private watchActive(): void {
    this.$emit('input', this.active)
    if (this.active.length > 0) {
      const documentsSidebarWrapperRef: any = this.$refs.documentsSidebarWrapper
      documentsSidebarWrapperRef.active = []
    }
  }

  created() {
    this.loadGroups()
  }

  addGroup(group: Group | null): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AddGroupDialog,
      meta: {
        parent: group,
        project: this.project,
        onSuccess: async (createGroup: Group) => {
          await this.loadGroups()
          if (group) {
            this.open.push(group)
            this.active = [createGroup]
          }
        }
      }
    })
  }

  editGroup(group: Group): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditGroupDialog,
      meta: {
        group,
        project: this.project,
        onSuccess: this.loadGroups
      }
    })
  }

  deleteGroup(group: Group): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.views.projects.projects-show.documents-sidebar.delete-dialog.title'),
        text: __('company.components.views.projects.projects-show.documents-sidebar.delete-dialog.text', { name: group.name }),
        onYes: () => GroupService.destroy(this.company, this.project, group)
          .then(this.loadGroups)
      }
    })
  }

  toggle(event: Event, group: Group, open: boolean): void {
    event.stopPropagation()

    if (open) {
      this.open = this.open.filter((item: Group) => item.uuid !== group.uuid)
      return
    }

    this.open.push(group)
  }

  private async loadGroups() {
    await GroupService.tree(this.company, this.project)
      .then((groups: Group[]) => {
        this.items = groups

        this.open = this.items
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
